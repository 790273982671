import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
import ImageGallery from "react-image-gallery"
const images = [
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-1.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-1.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-2.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-2.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-3.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-3.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-4.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-4.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-5.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-5.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-6.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-6.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-7.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-7.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-8.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-8.jpg')
    },
    {
        original: require('../images/amazon-mdsc/AMDSChallenge-9.jpg'),
        thumbnail: require('../images/amazon-mdsc/AMDSChallenge-9.jpg')
    },
]

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Amazon Master Data Science Challenge</h1>
            <h4>January - March 2020</h4>
            <p>
                I worked on this project with a team of four undergraduate students, in a Master's level competition sponsored by Amazon. With first place winning $15,000 and second getting $10,000, we wanted to do the best job we could. In the competition, we were supposed to predict weather's impact on deliveries using the past two years worth of data. The competition was separated into two rounds, a video presentation of what the plans were with the data, followed by a further selected group to get the actual data to create the best model.
            </p>
            <p>
                The first round of the competition involved making a video presentation in which we discussed our ideas of separating the data by general location, and finding parallels with locations that reacted similarly to certain weather events. We also talked about our plans to deseasonalize the data using a time series to remove the bias of certain months, weeks, and days. The next round is when we got our hands on the data, we performed this seasonal adjustment, followed by training on the cities to make our models as accurate as possible. In the end, we were named finalists, and ended up placing in the top four of the competition, a great achievement for a group of undergraduate students. You can see our written presentation below:
            </p>
            <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>
        </ProjectPage>
    </div>)
}

export default IndexPage
